import { useState } from 'react';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import actions from '~/app/entities/actions';
import { useArchiveUnarchive } from '~/app/entities/utils';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import { mapRoute } from '~/services/requests';
import { useEncodedCurrentRoute } from '~/app/shared/hooks';
import {
  EDIT_PROGRAM_PERMISSION,
  CREATE_MENTORSHIP_SESSION_PERMISSION,
  CREATE_MENTORSHIP_SESSION_FOR_OTHERS_PERMISSION,
  CONTENT_ITEM_PERMISSIONS,
} from '~/app/shared/permissions';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import { includes } from 'lodash-es';
import { useMentorshipProgramLabels } from '~/features/mentorship_programs/hooks/useMentorshipProgramLabels';
import {
  ContentItemSettingsMenu,
  useContentItemSettingsMenuState,
} from '~/common/components/ContentItemSettingsMenu';
import { Typography, ListItemIcon, Divider, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Group';

function MentorshipProgramContextMenu(props: any) {
  const { content } = props;

  const {
    public_id: publicId,
    public_id_and_slug: publicIdAndSlug,
    is_archived: isArchived,
  } = content;

  const { menteeLabelPlural, mentorLabelPlural } = useMentorshipProgramLabels(content);

  const canEdit = includes(content.permissions, EDIT_PROGRAM_PERMISSION);
  const canCreateSession =
    includes(content.permissions, CREATE_MENTORSHIP_SESSION_PERMISSION) ||
    includes(content.permissions, CREATE_MENTORSHIP_SESSION_FOR_OTHERS_PERMISSION);
  const canManage =
    includes(content.permissions, CREATE_MENTORSHIP_SESSION_FOR_OTHERS_PERMISSION) ||
    includes(content.permissions, CONTENT_ITEM_PERMISSIONS.assign);
  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const currentRoute = useEncodedCurrentRoute();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const { anchorEl, handleOpenMenu, handleCloseMenu } = useContentItemSettingsMenuState();

  const {
    archiveItem,
    unarchiveItem,
    isUpdating: isTogglingArchive,
  } = useArchiveUnarchive(actions.mentorshipProgram.archive);

  const handleToggleArchive = (action: 'archive' | 'unarchive') => {
    if (action === 'archive') {
      archiveItem(publicId);
    } else {
      unarchiveItem(publicId);
    }
  };

  if (!canEdit) {
    return null;
  }

  const editRouteUrl = contentTypeRoutes[CONTENT_TYPES.mentorship_program].edit({
    public_id_and_slug: publicIdAndSlug,
  });
  const scheduleRouteUrl = `${mapRoute('mentorshipSessionCreate', {
    public_id_and_slug: publicIdAndSlug,
  })}?origin=${currentRoute}`;
  const manageMentorsRouteUrl = mapRoute('mentorshipProgramManageMentors', {
    public_id_and_slug: publicIdAndSlug,
  });
  const manageMenteesRouteUrl = mapRoute('mentorshipProgramManageMentees', {
    public_id_and_slug: publicIdAndSlug,
  });

  return (
    <ContentItemSettingsMenu.Provider
      anchorEl={anchorEl}
      handleOpenMenu={handleOpenMenu}
      handleCloseMenu={handleCloseMenu}
    >
      <ContentItemSettingsMenu.ButtonTrigger />
      <ContentItemSettingsMenu.Menu>
        <ContentItemSettingsMenu.EditMenuItem routeUrl={editRouteUrl} />

        {canCreateSession && (
          <MenuItem disableRipple component={Link} to={scheduleRouteUrl}>
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <Typography>Schedule</Typography>
          </MenuItem>
        )}

        <Divider />

        {canManage && (
          <>
            <ContentItemSettingsMenu.ViewLogsMenuItem onClick={() => setShowLogModal(true)} />

            <MenuItem disableRipple component={Link} to={manageMentorsRouteUrl}>
              <ListItemIcon>
                <GroupIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Manage {mentorLabelPlural}</Typography>
            </MenuItem>

            <MenuItem disableRipple component={Link} to={manageMenteesRouteUrl}>
              <ListItemIcon>
                <GroupIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Manage {menteeLabelPlural}</Typography>
            </MenuItem>
          </>
        )}

        <Divider />

        <ContentItemSettingsMenu.ToggleArchiveMenuItem
          isArchived={isArchived}
          onToggleArchive={handleToggleArchive}
          disabled={isTogglingArchive}
        />

        {isArchived && (
          <ContentItemSettingsMenu.DeleteMenuItem onClick={() => setShowDeleteModal(true)} />
        )}
      </ContentItemSettingsMenu.Menu>
      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </ContentItemSettingsMenu.Provider>
  );
}

export default MentorshipProgramContextMenu;

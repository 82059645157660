import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';

import { eventAttendeeEmailListSchema } from '~/app/entities/schema';
import EventArchiveUnarchiveModal from '~/app/event/components/EventArchiveUnarchiveModal';
import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import EventBulkDuplicateModal from '~/app/event-shared/components/EventBulkDuplicate/EventBulkDuplicateModal';
import EventSurveyModal from '~/app/event-shared/components/EventSurveyModal';
import { toast } from '~/app/notifications/components/NotificationCenter';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import colors from '~/services/colors';
import confirmAlert from '~/services/confirm-alert';
import { useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import SocialSharingModal from '~/app/shared-modals/components/SocialSharingModal';
import IconButton from '~/app/shared/components/IconButton';
import Text from '~/app/shared/components/Text';
import { STATUS_DONE } from '~/app/shared/constants';
import { useToggles, useCopyToClipboard, useCurrentUser } from '~/app/shared/hooks';
import * as permissions from '~/app/shared/permissions';
import { includes, join, size } from 'lodash-es';

import EventDeleteModal from '../EventDeleteModal';
import {
  ContentItemSettingsMenu,
  useContentItemSettingsMenuState,
} from '~/common/components/ContentItemSettingsMenu';
import { Divider, ListItemIcon, MenuItem, Typography } from '@mui/material';
import CustomIcon from '~/app/shared/components/Icon';
import { Link } from 'react-router-dom';
import CachedIcon from '@mui/icons-material/Cached';
import MailIcon from '@mui/icons-material/Mail';

const ConfirmationDialogContent = () => {
  return (
    <Text block size="h4" color={colors.neutral900}>
      You&apos;re force syncing this event to calendar. This action will <b>notify users</b>. Are
      you sure you want to force sync this event?
    </Text>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 12px;
  }
`;

export const STATUS_GOING = 'going';
export const STATUS_WAITLIST = 'wait_list';

const EventContextMenu = ({ content: event, contextMenuExtraProps }) => {
  const { is_archived: isArchived } = event;

  const { currentUser } = contextMenuExtraProps;

  const {
    sync_to_google_calendar: syncToGoogleCalendar,
    sync_to_outlook_calendar: syncToOutlookCalendar,
    include_ics_file_in_event_emails: includeIcsFileInEventEmails,
  } = currentUser;

  const { toggle_calendar_force_sync: toggleCalendarForceSync } = useToggles();
  const { copy_emails_default_separator: copyEmailsDefaultSeparator } = useCurrentUser();

  const { trackActivity } = useMetrics();

  const hasAnySyncToCalendarOn =
    syncToGoogleCalendar || syncToOutlookCalendar || includeIcsFileInEventEmails;

  const canEdit = includes(event.permissions, permissions.EDIT_EVENT_PERMISSION);
  const canManageAttendance = includes(
    event.permissions,
    permissions.MANAGE_EVENT_ATTENDANCE_PERMISSION
  );
  const [resyncResource] = useEntities(actions.calendar.resyncResourceToCalendar, null);

  const CLIPBOARD_TIMEOUT = 2000;
  const { copyToClipboard } = useCopyToClipboard(CLIPBOARD_TIMEOUT);

  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [showBulkDuplicateModal, setShowBulkDuplicateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveUnarchiveModal, setShowArchiveUnarchiveModal] = useState(false);
  const [showSocialSharingModal, setShowSocialSharingModal] = useState(false);

  const { anchorEl, handleOpenMenu, handleCloseMenu } = useContentItemSettingsMenuState();

  const handleResyncResource = () => {
    resyncResource({
      pk: event.id,
      type: 'event',
    });
    toast.success('Force Synced successfully!');
  };

  const forceSyncToCalendar = () => {
    if (syncToOutlookCalendar || includeIcsFileInEventEmails) {
      return confirmAlert({
        title: 'Confirm Calendar Force Sync?',
        content: () => <ConfirmationDialogContent />,
        onConfirm: handleResyncResource,
        confirmLabel: 'Confirm Force Sync',
        isDangerSecondaryAction: true,
      });
    }
    return handleResyncResource();
  };

  const [fetchEventsEmail] = useEntities(
    actions.event.retrieveDetailsEmail,
    ({ status, data }) => {
      if (status === STATUS_DONE) {
        const emails = data.emails_to_copy;
        const count = size(emails);

        if (count) {
          toast.success(`${count} ${count === 1 ? 'email' : 'emails'} copied.`);
          copyToClipboard(join(emails, copyEmailsDefaultSeparator));
        } else {
          toast.warning('No items to be copied.');
        }
      }
    },
    {
      schema: [eventAttendeeEmailListSchema],
    }
  );

  const copyToClipboardAndRenderSuccessMessage = (status) => {
    fetchEventsEmail(event.public_id, {
      status,
      page_size: 2000,
    });
  };

  const getSocialSharingText = () => {
    const { name, url } = event;
    return `Check out "${name}": ${url}`;
  };

  if (!canEdit) return null;

  const editRouteUrl = mapRoute('eventEdit', { public_id_and_slug: event.public_id_and_slug });
  const duplicateRouteUrl = mapRoute('eventNewFromClone', { public_id: event.public_id });
  const rosterRouteUrl = mapRoute('eventManageAttendance', {
    public_id_and_slug: event.public_id_and_slug,
  });

  return (
    <>
      <Container>
        {event.chatter_share && (
          <IconButton
            title="Share on Chatter"
            iconName="share"
            color={colors.neutral400}
            hoverColor={colors.action600}
            onClick={() => setShowSocialSharingModal(true)}
            width={28}
            height={28}
          />
        )}

        <ContentItemSettingsMenu.Provider
          anchorEl={anchorEl}
          handleOpenMenu={handleOpenMenu}
          handleCloseMenu={handleCloseMenu}
        >
          <ContentItemSettingsMenu.ButtonTrigger />
          <ContentItemSettingsMenu.Menu>
            <ContentItemSettingsMenu.EditMenuItem routeUrl={editRouteUrl} />

            <MenuItem disableRipple component={Link} to={duplicateRouteUrl}>
              <ListItemIcon>
                <CustomIcon name="clone" width={16} height={16} color={colors.neutral600} />
              </ListItemIcon>
              <Typography>Duplicate</Typography>
            </MenuItem>

            <MenuItem
              disableRipple
              onClick={() => {
                setShowBulkDuplicateModal(true);
                handleCloseMenu();
              }}
            >
              <ListItemIcon>
                <CustomIcon
                  name="multiple-clone"
                  width={16}
                  height={16}
                  color={colors.neutral600}
                />
              </ListItemIcon>
              <Typography>Bulk Duplicate</Typography>
            </MenuItem>

            <Divider />

            {toggleCalendarForceSync && hasAnySyncToCalendarOn && (
              <MenuItem
                disableRipple
                onClick={() => {
                  forceSyncToCalendar();
                  handleCloseMenu();
                }}
              >
                <ListItemIcon>
                  <CachedIcon fontSize="small" />
                </ListItemIcon>
                <Typography>Force Calendar Sync</Typography>
              </MenuItem>
            )}

            <MenuItem
              disableRipple
              onClick={() => {
                copyToClipboardAndRenderSuccessMessage(STATUS_GOING);
                handleCloseMenu();
              }}
            >
              <ListItemIcon>
                <MailIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Copy E-mails (Attendees)</Typography>
            </MenuItem>

            <MenuItem
              disableRipple
              onClick={() => {
                copyToClipboardAndRenderSuccessMessage(STATUS_WAITLIST);
                handleCloseMenu();
              }}
            >
              <ListItemIcon>
                <MailIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Copy E-mails (Waitlisted)</Typography>
            </MenuItem>

            <ContentItemSettingsMenu.ViewRosterMenuItem routeUrl={rosterRouteUrl} />

            <ContentItemSettingsMenu.ManageSurveysMenuItem
              onClick={() => setShowSurveyModal(true)}
            />

            {canManageAttendance && (
              <ContentItemSettingsMenu.ViewLogsMenuItem onClick={() => setShowLogModal(true)} />
            )}

            <Divider />

            <ContentItemSettingsMenu.ToggleArchiveMenuItem
              isArchived={isArchived}
              onToggleArchive={() => setShowArchiveUnarchiveModal(true)}
              archiveText="Cancel"
              unarchiveText="Restore"
            />

            {isArchived && (
              <ContentItemSettingsMenu.DeleteMenuItem onClick={() => setShowDeleteModal(true)} />
            )}
          </ContentItemSettingsMenu.Menu>
        </ContentItemSettingsMenu.Provider>
      </Container>
      {showSurveyModal && (
        <EventSurveyModal event={event} handleClose={() => setShowSurveyModal(false)} />
      )}
      {showBulkDuplicateModal && (
        <EventBulkDuplicateModal
          event={event}
          trackActivity={trackActivity}
          handleClose={() => setShowBulkDuplicateModal(false)}
        />
      )}
      {showDeleteModal && (
        <EventDeleteModal event={event} handleClose={() => setShowDeleteModal(false)} />
      )}
      {showArchiveUnarchiveModal && (
        <EventArchiveUnarchiveModal
          event={event}
          handleClose={() => setShowArchiveUnarchiveModal(false)}
        />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={event} handleClose={() => setShowLogModal(false)} />
      )}
      {showSocialSharingModal && (
        <SocialSharingModal
          service="chatter"
          serviceName="Chatter"
          textMessage={getSocialSharingText()}
          handleClose={() => setShowSocialSharingModal(false)}
        />
      )}
    </>
  );
};

EventContextMenu.propTypes = {
  content: PropTypes.object,
  contextMenuExtraProps: PropTypes.object,
};

export default EventContextMenu;

import * as React from 'react';

import {
  IconButton,
  IconButtonProps,
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  MenuItem,
  Typography,
  ListItemIcon,
  MenuItemProps,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import colors from '~/services/colors';
import CustomIcon from '~/app/shared/components/Icon';

type ContentItemSettingsMenuState = {
  anchorEl: HTMLButtonElement | null;
  handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseMenu: () => void;
};

function useContentItemSettingsMenuState(): ContentItemSettingsMenuState {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
  };
}

type ContentItemSettingsMenuCtxType = ContentItemSettingsMenuState;
const ContentItemSettingsMenuCtx = React.createContext<ContentItemSettingsMenuCtxType | null>(null);

type ContentItemSettingsMenuProviderProps = ContentItemSettingsMenuCtxType & {
  children: React.ReactNode;
};

function ContentItemSettingsMenuProvider(props: ContentItemSettingsMenuProviderProps) {
  const { children, ...ctxValues } = props;

  return (
    <ContentItemSettingsMenuCtx.Provider value={ctxValues}>
      {children}
    </ContentItemSettingsMenuCtx.Provider>
  );
}

function useContentItemSettingsMenuCtx() {
  const ctx = React.useContext(ContentItemSettingsMenuCtx);

  if (!ctx) {
    throw new Error(
      'useContentItemSettingsMenuCtx must be used within a ContentItemSettingsMenuProvider'
    );
  }

  return ctx;
}

type ButtonTriggerProps = IconButtonProps;

function ButtonTrigger(props: ButtonTriggerProps) {
  const { anchorEl, handleOpenMenu } = useContentItemSettingsMenuCtx();

  const isMenuOpen = Boolean(anchorEl);

  return (
    <IconButton
      aria-label="Settings"
      data-testid="Settings"
      data-cy="context-menu-button"
      aria-controls={isMenuOpen ? 'context-menu' : undefined}
      aria-expanded={isMenuOpen ? 'true' : undefined}
      aria-haspopup="true"
      size="small"
      {...props}
      id="settings-menu-button"
      onClick={handleOpenMenu}
    >
      <MoreVertIcon />
    </IconButton>
  );
}

type MenuProps = Omit<MuiMenuProps, 'anchorEl' | 'open' | 'onClose'>;

function Menu(props: MenuProps) {
  const { children, ...rest } = props;

  const { anchorEl, handleCloseMenu } = useContentItemSettingsMenuCtx();

  const isMenuOpen = Boolean(anchorEl);

  return (
    <MuiMenu
      id="context-menu"
      MenuListProps={{
        'aria-labelledby': 'settings-menu-button',
        // @ts-expect-error - This prop is allowed
        'data-testid': 'DropdownOptions',
        'data-cy': 'DropdownOptions',
      }}
      {...rest}
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleCloseMenu}
    >
      {children}
    </MuiMenu>
  );
}

type EditMenuItemProps = {
  routeUrl: string;
} & MenuItemProps;

function EditMenuItem(props: EditMenuItemProps) {
  const { routeUrl, ...restProps } = props;

  return (
    <MenuItem disableRipple component={Link} to={routeUrl} data-cy="edit" {...restProps}>
      <ListItemIcon>
        <EditIcon fontSize="small" />
      </ListItemIcon>
      <Typography>Edit</Typography>
    </MenuItem>
  );
}

type ViewRosterMenuItemProps = {
  routeUrl: string;
} & MenuItemProps;

function ViewRosterMenuItem(props: ViewRosterMenuItemProps) {
  const { routeUrl, ...restProps } = props;

  return (
    <MenuItem disableRipple component={Link} to={routeUrl} {...restProps}>
      <ListItemIcon>
        <GroupIcon fontSize="small" />
      </ListItemIcon>
      <Typography>View Roster</Typography>
    </MenuItem>
  );
}

type ManageSurveysMenuItemProps = MenuItemProps;

function ManageSurveysMenuItem(props: ManageSurveysMenuItemProps) {
  const { onClick: onClickProp, ...restProps } = props;

  const { handleCloseMenu } = useContentItemSettingsMenuCtx();

  return (
    <MenuItem
      disableRipple
      onClick={(e) => {
        onClickProp?.(e);
        handleCloseMenu();
      }}
      {...restProps}
    >
      <ListItemIcon>
        <CustomIcon name="survey" width={16} height={16} color={colors.neutral600} />
      </ListItemIcon>
      <Typography>Manage Surveys</Typography>
    </MenuItem>
  );
}

type ArchiveMenuItemProps = MenuItemProps;

function ArchiveMenuItem(props: ArchiveMenuItemProps) {
  const { onClick: onClickProp, ...restProps } = props;

  const { handleCloseMenu } = useContentItemSettingsMenuCtx();

  return (
    <MenuItem
      disableRipple
      onClick={(e) => {
        onClickProp?.(e);
        handleCloseMenu();
      }}
      data-cy="archive"
      {...restProps}
    >
      <ListItemIcon>
        <ArchiveIcon fontSize="small" />
      </ListItemIcon>
      <Typography>Archive</Typography>
    </MenuItem>
  );
}

type ToggleArchiveMenuItemProps = {
  isArchived: boolean;
  onToggleArchive: (action: 'archive' | 'unarchive') => void;
  archiveText?: string;
  unarchiveText?: string;
} & Omit<MenuItemProps, 'onClick'>;

function ToggleArchiveMenuItem(props: ToggleArchiveMenuItemProps) {
  const {
    isArchived,
    onToggleArchive,
    archiveText = 'Archive',
    unarchiveText = 'Unarchive',
    ...restProps
  } = props;

  const { handleCloseMenu } = useContentItemSettingsMenuCtx();

  return (
    <MenuItem
      disableRipple
      onClick={() => {
        onToggleArchive(isArchived ? 'unarchive' : 'archive');
        handleCloseMenu();
      }}
      data-cy={isArchived ? 'unarchive' : 'archive'}
      {...restProps}
    >
      <ListItemIcon>
        {isArchived ? <UnarchiveIcon fontSize="small" /> : <ArchiveIcon fontSize="small" />}
      </ListItemIcon>
      <Typography>{isArchived ? unarchiveText : archiveText}</Typography>
    </MenuItem>
  );
}

type DeleteMenuItemProps = MenuItemProps;

function DeleteMenuItem(props: DeleteMenuItemProps) {
  const { onClick: onClickProp, ...restProps } = props;

  const { handleCloseMenu } = useContentItemSettingsMenuCtx();

  return (
    <MenuItem
      disableRipple
      onClick={(e) => {
        onClickProp?.(e);
        handleCloseMenu();
      }}
      data-cy="delete"
      {...restProps}
    >
      <ListItemIcon>
        <CustomIcon name="delete" width={16} height={16} color={colors.error600} />
      </ListItemIcon>
      <Typography color={colors.error600}>Delete</Typography>
    </MenuItem>
  );
}

type ViewLogsMenuItemProps = MenuItemProps;

function ViewLogsMenuItem(props: ViewLogsMenuItemProps) {
  const { onClick: onClickProp, ...restProps } = props;

  const { handleCloseMenu } = useContentItemSettingsMenuCtx();

  return (
    <MenuItem
      disableRipple
      onClick={(e) => {
        onClickProp?.(e);
        handleCloseMenu();
      }}
      {...restProps}
    >
      <ListItemIcon>
        <CustomIcon name="log" width={16} height={16} color={colors.neutral600} />
      </ListItemIcon>
      <Typography>View Logs</Typography>
    </MenuItem>
  );
}

const ContentItemSettingsMenu = Object.assign(
  {},
  {
    Provider: ContentItemSettingsMenuProvider,
    ButtonTrigger,
    Menu,
    EditMenuItem,
    ViewRosterMenuItem,
    ManageSurveysMenuItem,
    ArchiveMenuItem,
    ToggleArchiveMenuItem,
    DeleteMenuItem,
    ViewLogsMenuItem,
  }
);

export { ContentItemSettingsMenu, useContentItemSettingsMenuState, useContentItemSettingsMenuCtx };

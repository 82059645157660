import React, { useState } from 'react';

import { map } from 'lodash-es';
import { useLabels } from '~/app/shared/hooks';
import { DropdownFilter } from '~/app/backoffice/components/Dashboard/Filters';
import { useQuery } from '@tanstack/react-query';
import { queries } from '~/queries';

const PAGE_SIZE = 15;

const makeOptions = (data) => {
  return map(data.results, ({ public_id, name }) => ({
    value: public_id,
    label: name,
  }));
};

const MentorshipProgramFilter = (props) => {
  const { label_mentorship_program: labelProgram } = useLabels();
  const [queryParams, setQueryParams] = useState({
    view_mode: 'filter',
    page_size: PAGE_SIZE,
  });
  const { data: options } = useQuery({
    // eslint-disable-next-line unicorn/no-array-callback-reference
    ...queries.mentorship_program.filter(queryParams),
    select: makeOptions,
  });

  const fetchOptions = ({ q }) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      q,
    }));
  };

  return (
    <DropdownFilter
      label={labelProgram}
      options={options}
      loading={false}
      fetchOptions={fetchOptions}
      {...props}
      param="program"
    />
  );
};

export default MentorshipProgramFilter;

import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useHistory } from 'react-router';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import CodelabFormModal from '~/app/codelab/components/CodelabImportFormModal/CodelabImportFormModal';
import { previewCodelabRequest } from '~/app/codelab/services';
import { ContentItemContextMenu } from '~/app/content-items/components/interfaces';
import actions from '~/app/entities/actions';
import { useArchiveRestore } from '~/app/entities/utils';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { queries } from '~/queries';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import { mapRoute } from '~/services/requests';
import ContentSurveyModal from '~/app/shared/components/ContentSurveyModal';
import { CONTENT_ITEM_PERMISSIONS } from '~/app/shared/permissions';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import { get, includes } from 'lodash-es';
import {
  ContentItemSettingsMenu,
  useContentItemSettingsMenuState,
} from '~/common/components/ContentItemSettingsMenu';
import { Divider, ListItemIcon, MenuItem, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useContentTypeRoutes } from '~/app/catalog/hooks';

const CodelabContextMenu = (props: ContentItemContextMenu) => {
  const { content, refreshContent } = props;

  const { anchorEl, handleOpenMenu, handleCloseMenu } = useContentItemSettingsMenuState();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showCodelabFormModal, setShowCodelabFormModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const history = useHistory();

  const queryClient = useQueryClient();

  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });

  const handleRefreshContent = () => {
    queryClient.invalidateQueries({
      queryKey: queries.content_items.details(content.public_id).queryKey,
    });

    refreshContent?.();
  };

  const {
    archiveItem,
    restoreItem,
    isUpdating: isTogglingArchive,
  } = useArchiveRestore(actions.codelab, handleRefreshContent);

  const handleToggleArchive = (action: 'archive' | 'unarchive') => {
    if (action === 'archive') {
      archiveItem(id);
    } else {
      restoreItem(id);
    }
  };

  const { id, public_id_and_slug: publicIdAndSlug, permissions } = content;
  const isArchived = Boolean(get(content, 'archival.archived_at'));

  const canManage = includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canAssign = includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);

  if (!canManage) {
    return null;
  }

  const editRouteUrl = contentTypeRoutes[CONTENT_TYPES.codelab].edit({
    public_id_and_slug: publicIdAndSlug,
  });

  const rosterRouteUrl = mapRoute('contentItemManageAssignments', {
    public_id_and_slug: publicIdAndSlug,
  });

  const handlePreviewCodelabClick = async () => {
    await previewCodelabRequest(id);
  };

  return (
    <ContentItemSettingsMenu.Provider
      anchorEl={anchorEl}
      handleOpenMenu={handleOpenMenu}
      handleCloseMenu={handleCloseMenu}
    >
      <ContentItemSettingsMenu.ButtonTrigger />
      <ContentItemSettingsMenu.Menu>
        <ContentItemSettingsMenu.EditMenuItem routeUrl={editRouteUrl} />

        <MenuItem
          disableRipple
          onClick={() => {
            setShowCodelabFormModal(true);
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <RefreshIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Import new version</Typography>
        </MenuItem>

        <MenuItem
          disableRipple
          onClick={() => {
            handlePreviewCodelabClick();
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <VisibilityIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Preview</Typography>
        </MenuItem>

        <Divider />

        {canAssign && <ContentItemSettingsMenu.ViewRosterMenuItem routeUrl={rosterRouteUrl} />}

        <ContentItemSettingsMenu.ManageSurveysMenuItem onClick={() => setShowSurveyModal(true)} />

        {canAssign && (
          <ContentItemSettingsMenu.ViewLogsMenuItem onClick={() => setShowLogModal(true)} />
        )}

        <Divider />

        <ContentItemSettingsMenu.ToggleArchiveMenuItem
          isArchived={isArchived}
          onToggleArchive={handleToggleArchive}
          disabled={isTogglingArchive}
        />

        {isArchived && (
          <ContentItemSettingsMenu.DeleteMenuItem onClick={() => setShowDeleteModal(true)} />
        )}
      </ContentItemSettingsMenu.Menu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}

      {showSurveyModal && (
        <ContentSurveyModal content={content} handleClose={() => setShowSurveyModal(false)} />
      )}

      {showCodelabFormModal && (
        <CodelabFormModal
          handleClose={() => setShowCodelabFormModal(false)}
          codelabId={id}
          handleUploadCompleted={(publicIdAndSlug: string) => {
            toast.success(
              'New version uploaded successfully',
              'You can preview the new codelab version.'
            );
            history.push(
              mapRoute('standAloneCodelabDetail', {
                public_id_and_slug: publicIdAndSlug,
              })
            );
            setShowCodelabFormModal(false);
          }}
        />
      )}

      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </ContentItemSettingsMenu.Provider>
  );
};

export default CodelabContextMenu;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queries } from '~/queries';
import { apiPost, apiPatch } from '~/services/requests';

export function useSurveyCreate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: any) => {
      const { data: response } = await apiPost('surveys_api:surveys', null, data);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.surveys.list._def,
      });
    },
  });
}

export function useSurveyEdit(id: number) {
  /*const queryClient = useQueryClient();*/
  return useMutation({
    mutationFn: async (data: any) => {
      const { data: response } = await apiPatch('surveys_api:details', id, data);
      return response;
    },
  });
}

import * as React from 'react';

import {
  CONTENT_TYPES,
  LEARNING_TYPE_ICONS_MAPPING,
  LEARNING_TYPES,
} from '~/app/catalog/constants';
import { ContentItemContextMenu } from '~/app/content-items/components/interfaces';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import colors from '~/services/colors';
import ContentSurveyModal from '~/app/shared/components/ContentSurveyModal';
import { useLabels } from '~/app/shared/hooks';
import { CONTENT_ITEM_PERMISSIONS } from '~/app/shared/permissions';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import { ConvertToTaskModal } from '~/app/task/components/TaskConverterModal';
import { includes, get } from 'lodash-es';
import { useArchiveArticle, useUnarchiveArticle } from '../../api/mutations';
import { Divider, ListItemIcon, MenuItem, Typography } from '@mui/material';
import CustomIcon from '~/app/shared/components/Icon';
import {
  ContentItemSettingsMenu,
  useContentItemSettingsMenuState,
} from '~/common/components/ContentItemSettingsMenu';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import { mapRoute } from '~/services/requests';

const ArticleContextMenu = (props: ContentItemContextMenu) => {
  const { content, contextMenuExtraProps } = props;
  const { anchorEl, handleOpenMenu, handleCloseMenu } = useContentItemSettingsMenuState();

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showSurveyModal, setShowSurveyModal] = React.useState(false);
  const [showConvertToTaskModal, setShowConvertToTaskModal] = React.useState(false);
  const [showLogModal, setShowLogModal] = React.useState(false);

  const { label_task: labelTask } = useLabels();

  const { isTrackItem } = contextMenuExtraProps;
  const { public_id: publicId, public_id_and_slug: publicIdAndSlug, permissions } = content;
  const isArchived = Boolean(get(content, 'archival.archived_at'));

  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const editRouteUrl = contentTypeRoutes[CONTENT_TYPES.article].edit({
    public_id: publicId,
  });

  const archiveArticle = useArchiveArticle(content);
  const unarchiveArticle = useUnarchiveArticle(content);

  const isTogglingArchive =
    archiveArticle.status === 'loading' || unarchiveArticle.status === 'loading';

  const handleToggleArchive = (action: 'archive' | 'unarchive') => {
    if (action === 'archive') {
      archiveArticle.mutateAsync();
    } else {
      unarchiveArticle.mutateAsync();
    }
  };

  const canManage = includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canAssign = includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);

  if (!canManage) {
    return null;
  }

  const rosterRouteUrl = mapRoute('contentItemManageAssignments', {
    public_id_and_slug: publicIdAndSlug,
  });

  return (
    <ContentItemSettingsMenu.Provider
      anchorEl={anchorEl}
      handleOpenMenu={handleOpenMenu}
      handleCloseMenu={handleCloseMenu}
    >
      <ContentItemSettingsMenu.ButtonTrigger />
      <ContentItemSettingsMenu.Menu>
        <ContentItemSettingsMenu.EditMenuItem routeUrl={editRouteUrl} />

        {isTrackItem && (
          <MenuItem
            disableRipple
            onClick={() => {
              setShowConvertToTaskModal(true);
              handleCloseMenu();
            }}
          >
            <ListItemIcon>
              <CustomIcon
                name={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.tasks]}
                width={16}
                height={16}
                color={colors.neutral600}
              />
            </ListItemIcon>
            <Typography>Convert to {labelTask}</Typography>
          </MenuItem>
        )}

        <Divider />

        {canAssign && <ContentItemSettingsMenu.ViewRosterMenuItem routeUrl={rosterRouteUrl} />}

        <ContentItemSettingsMenu.ManageSurveysMenuItem onClick={() => setShowSurveyModal(true)} />

        {canAssign && (
          <ContentItemSettingsMenu.ViewLogsMenuItem onClick={() => setShowLogModal(true)} />
        )}

        <Divider />

        <ContentItemSettingsMenu.ToggleArchiveMenuItem
          isArchived={isArchived}
          onToggleArchive={handleToggleArchive}
          disabled={isTogglingArchive}
        />

        {isArchived && (
          <ContentItemSettingsMenu.DeleteMenuItem onClick={() => setShowDeleteModal(true)} />
        )}
      </ContentItemSettingsMenu.Menu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}

      {showSurveyModal && (
        <ContentSurveyModal content={content} handleClose={() => setShowSurveyModal(false)} />
      )}

      {showConvertToTaskModal && (
        <ConvertToTaskModal
          content={content}
          handleClose={() => setShowConvertToTaskModal(false)}
        />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </ContentItemSettingsMenu.Provider>
  );
};

export default ArticleContextMenu;

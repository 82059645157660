import { useState } from 'react';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import { ContentItemContextMenu } from '~/app/content-items/components/interfaces';
import actions from '~/app/entities/actions';
import { useArchiveUnarchive } from '~/app/entities/utils';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import { mapRoute } from '~/services/requests';
import { useEncodedCurrentRoute } from '~/app/shared/hooks';
import { CONTENT_ITEM_PERMISSIONS } from '~/app/shared/permissions';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import { get, includes } from 'lodash-es';
import {
  ContentItemSettingsMenu,
  useContentItemSettingsMenuState,
} from '~/common/components/ContentItemSettingsMenu';
import { Typography, ListItemIcon, Divider, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

function ProgramSessionsContextMenu(props: ContentItemContextMenu) {
  const { content } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const { anchorEl, handleOpenMenu, handleCloseMenu } = useContentItemSettingsMenuState();

  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const currentRoute = useEncodedCurrentRoute();
  const {
    archiveItem,
    unarchiveItem,
    isUpdating: isTogglingArchive,
  } = useArchiveUnarchive(actions.program.archive);

  const handleToggleArchive = (action: 'archive' | 'unarchive') => {
    if (action === 'archive') {
      archiveItem(publicId);
    } else {
      unarchiveItem(publicId);
    }
  };

  const { public_id: publicId, public_id_and_slug: publicIdAndSlug, permissions } = content;
  const isArchived = Boolean(get(content, 'archival.archived_at'));

  const canManage = includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canAssign = includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);

  if (!canManage) {
    return null;
  }

  const editRouteUrl = contentTypeRoutes[CONTENT_TYPES.program].edit({
    public_id_and_slug: publicIdAndSlug,
  });
  const scheduleRouteUrl = `${mapRoute('sessionCreate')}?origin=${currentRoute}&program_id=${publicId}`;

  return (
    <ContentItemSettingsMenu.Provider
      anchorEl={anchorEl}
      handleOpenMenu={handleOpenMenu}
      handleCloseMenu={handleCloseMenu}
    >
      <ContentItemSettingsMenu.ButtonTrigger />
      <ContentItemSettingsMenu.Menu>
        <ContentItemSettingsMenu.EditMenuItem routeUrl={editRouteUrl} />

        <MenuItem disableRipple component={Link} to={scheduleRouteUrl}>
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Schedule</Typography>
        </MenuItem>

        <Divider />

        {canAssign && (
          <ContentItemSettingsMenu.ViewLogsMenuItem onClick={() => setShowLogModal(true)} />
        )}

        <Divider />

        <ContentItemSettingsMenu.ToggleArchiveMenuItem
          isArchived={isArchived}
          onToggleArchive={handleToggleArchive}
          disabled={isTogglingArchive}
        />

        {isArchived && (
          <ContentItemSettingsMenu.DeleteMenuItem onClick={() => setShowDeleteModal(true)} />
        )}
      </ContentItemSettingsMenu.Menu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </ContentItemSettingsMenu.Provider>
  );
}

export default ProgramSessionsContextMenu;

import { useState } from 'react';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import { ContentItemContextMenu } from '~/app/content-items/components/interfaces';
import actions from '~/app/entities/actions';
import { useArchiveUnarchive } from '~/app/entities/utils';
import { getFacilitatorsIds, getEventTypeEditableField } from '~/app/event-shared/services';
import EventTypeApplyToEventsModal from '~/app/event-types/components/EventTypeApplyToEventsModal';
import { EventType } from '~/app/event/interfaces';
import { toast } from '~/app/notifications/components/NotificationCenter';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import ContentSurveyModal from '~/app/shared/components/ContentSurveyModal';
import {
  CONTENT_ITEM_PERMISSIONS,
  CREATE_EVENT_TYPE_PERMISSION,
  EDIT_EVENT_TYPE_PERMISSION,
} from '~/app/shared/permissions';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import { includes, get } from 'lodash-es';
import {
  ContentItemSettingsMenu,
  useContentItemSettingsMenuState,
} from '~/common/components/ContentItemSettingsMenu';
import { Typography, ListItemIcon, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomIcon from '~/app/shared/components/Icon';
import { MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

function EventTypeContextMenu(props: ContentItemContextMenu) {
  const { content: contentItem } = props;

  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });

  const content: EventType = contentItem;
  const { public_id: publicId, public_id_and_slug: publicIdAndSlug, permissions } = content;

  const isArchived = get(content, 'is_archived', Boolean(get(content, 'archival.archived_at')));

  const canEdit =
    includes(permissions, EDIT_EVENT_TYPE_PERMISSION) ||
    includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canAssign = includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);
  const canManage =
    includes(permissions, EDIT_EVENT_TYPE_PERMISSION) ||
    includes(permissions, CREATE_EVENT_TYPE_PERMISSION) ||
    canAssign;

  const {
    archiveItem,
    unarchiveItem,
    isUpdating: isTogglingArchive,
  } = useArchiveUnarchive(actions.eventType.archive);

  const handleToggleArchive = (action: 'archive' | 'unarchive') => {
    if (action === 'archive') {
      archiveItem(publicId);
    } else {
      unarchiveItem(publicId);
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showPropagateModal, setShowPropagateModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const { anchorEl, handleOpenMenu, handleCloseMenu } = useContentItemSettingsMenuState();

  const { organizerId, presentersIds, coOrganizersIds } = getFacilitatorsIds(content);

  if (!canEdit) {
    return null;
  }

  content.organizer_id = organizerId;
  content.presenters_ids = presentersIds;
  content.co_organizers_ids = coOrganizersIds;

  const handleApplyToSubmit = async (fields, propagation) => {
    const url = ApiURLs['api_eventtypes:apply_changes']();
    const data = { event_type_id: content.public_id, fields, propagation };
    await fetchURL(url, { method: 'POST', body: JSON.stringify(data) });

    toast.success('Success!', 'The selected events will update in a few minutes!');
  };

  const editableFields = getEventTypeEditableField(content);

  const editRouteUrl = contentTypeRoutes[CONTENT_TYPES.eventtype].edit({
    public_id_and_slug: publicIdAndSlug,
  });
  const scheduleRouteUrl = mapRoute('eventNewFromTemplate', { public_id: publicId });
  const duplicateRouteUrl = `${mapRoute('eventTypeCreate')}?cloned=${publicId}`;
  const rosterRouteUrl = mapRoute('contentItemManageAssignments', {
    public_id_and_slug: publicIdAndSlug,
  });

  return (
    <ContentItemSettingsMenu.Provider
      anchorEl={anchorEl}
      handleOpenMenu={handleOpenMenu}
      handleCloseMenu={handleCloseMenu}
    >
      <ContentItemSettingsMenu.ButtonTrigger />
      <ContentItemSettingsMenu.Menu>
        <ContentItemSettingsMenu.EditMenuItem routeUrl={editRouteUrl} />

        <MenuItem disableRipple component={Link} to={duplicateRouteUrl}>
          <ListItemIcon>
            <CustomIcon name="clone" width={16} height={16} color={colors.neutral600} />
          </ListItemIcon>
          <Typography>Duplicate</Typography>
        </MenuItem>

        <MenuItem
          disableRipple
          onClick={() => {
            setShowPropagateModal(true);
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <CustomIcon name="edit" width={16} height={16} color={colors.neutral600} />
          </ListItemIcon>
          <Typography>Apply to Existing Events</Typography>
        </MenuItem>

        <MenuItem disableRipple component={Link} to={scheduleRouteUrl}>
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Schedule</Typography>
        </MenuItem>

        <Divider />

        {canAssign && <ContentItemSettingsMenu.ViewRosterMenuItem routeUrl={rosterRouteUrl} />}

        <ContentItemSettingsMenu.ManageSurveysMenuItem onClick={() => setShowSurveyModal(true)} />

        {canManage && (
          <ContentItemSettingsMenu.ViewLogsMenuItem onClick={() => setShowLogModal(true)} />
        )}

        <Divider />

        <ContentItemSettingsMenu.ToggleArchiveMenuItem
          isArchived={isArchived}
          onToggleArchive={handleToggleArchive}
          disabled={isTogglingArchive}
        />

        {isArchived && (
          <ContentItemSettingsMenu.DeleteMenuItem onClick={() => setShowDeleteModal(true)} />
        )}
      </ContentItemSettingsMenu.Menu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}

      {showSurveyModal && (
        <ContentSurveyModal content={content} handleClose={() => setShowSurveyModal(false)} />
      )}

      {showPropagateModal && (
        <EventTypeApplyToEventsModal
          eventFields={editableFields}
          handleClose={() => setShowPropagateModal(false)}
          handleSubmit={handleApplyToSubmit}
        />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </ContentItemSettingsMenu.Provider>
  );
}

export default EventTypeContextMenu;

import { useState } from 'react';
import { useHistory } from 'react-router';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import { ContentItemContextMenu } from '~/app/content-items/components/interfaces';
import CourseConfigModal from '~/app/course/components/CourseConfigModal';
import CourseFormModal from '~/app/course/components/CourseImportFormModal/CourseImportFormModal';
import { COURSE_STATUS_DRAFT, COURSE_STATUS_PUBLISHED } from '~/app/course/constants';
import { launchCourse } from '~/app/course/services';
import actions from '~/app/entities/actions';
import { useArchiveUnarchive } from '~/app/entities/utils';
import { toast } from '~/app/notifications/components/NotificationCenter';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import { mapRoute } from '~/services/requests';
import ContentSurveyModal from '~/app/shared/components/ContentSurveyModal';
import { CONTENT_ITEM_PERMISSIONS, COURSE_PERMISSIONS } from '~/app/shared/permissions';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import { includes, isEmpty, get } from 'lodash-es';
import {
  ContentItemSettingsMenu,
  useContentItemSettingsMenuState,
} from '~/common/components/ContentItemSettingsMenu';
import { MenuItem, ListItemIcon, Typography, Divider } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';

function CourseContextMenu(props: ContentItemContextMenu) {
  const { content, isRenderedWithinTrack = false } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCourseConfigModal, setShowCourseConfigModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showCourseFormModal, setShowCourseFormModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const { anchorEl, handleOpenMenu, handleCloseMenu } = useContentItemSettingsMenuState();

  const history = useHistory();
  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const {
    archiveItem,
    unarchiveItem,
    isUpdating: isTogglingArchive,
  } = useArchiveUnarchive(actions.course.archive);

  const handleToggleArchive = (action: 'archive' | 'unarchive') => {
    if (action === 'archive') {
      archiveItem(id);
    } else {
      unarchiveItem(id);
    }
  };

  const {
    id,
    public_id: publicId,
    public_id_and_slug: publicIdAndSlug,
    permissions,
    status,
    is_inline,
  } = content;
  const isArchived = Boolean(get(content, 'archival.archived_at'));
  const hasScormCloudID = !isEmpty(get(content, 'scorm_cloud_id'));

  const canManage = includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canAssign = includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);
  // We cannot preview CMI5 learning content
  const canPreview = includes(permissions, COURSE_PERMISSIONS.preview);

  if (!canManage) {
    return null;
  }

  const handlePreviewCourseClick = async () => {
    await launchCourse({
      courseId: publicId,
      isLaunchedInsideTrack: isRenderedWithinTrack,
      showPreview: true,
    });
  };

  const editRouteUrl = contentTypeRoutes[CONTENT_TYPES.course].edit({
    public_id_and_slug: publicIdAndSlug,
  });

  const rosterRouteUrl = mapRoute('contentItemManageAssignments', {
    public_id_and_slug: publicIdAndSlug,
  });

  const isPublishedOrDraft = includes([COURSE_STATUS_PUBLISHED, COURSE_STATUS_DRAFT], status);

  return (
    <ContentItemSettingsMenu.Provider
      anchorEl={anchorEl}
      handleOpenMenu={handleOpenMenu}
      handleCloseMenu={handleCloseMenu}
    >
      <ContentItemSettingsMenu.ButtonTrigger />
      <ContentItemSettingsMenu.Menu>
        <>
          <ContentItemSettingsMenu.EditMenuItem routeUrl={editRouteUrl} />

          <MenuItem
            disableRipple
            onClick={() => {
              setShowCourseFormModal(true);
              handleCloseMenu();
            }}
          >
            <ListItemIcon>
              <RefreshIcon fontSize="small" />
            </ListItemIcon>
            <Typography>Import new version</Typography>
          </MenuItem>

          {isPublishedOrDraft && (
            <MenuItem
              disableRipple
              onClick={() => {
                setShowCourseConfigModal(true);
                handleCloseMenu();
              }}
              disabled={!hasScormCloudID}
            >
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Advanced Settings</Typography>
            </MenuItem>
          )}

          <MenuItem
            disableRipple
            onClick={() => {
              handlePreviewCourseClick();
              handleCloseMenu();
            }}
            disabled={!canPreview}
          >
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <Typography>Preview</Typography>
          </MenuItem>

          <Divider />

          {canAssign && <ContentItemSettingsMenu.ViewRosterMenuItem routeUrl={rosterRouteUrl} />}

          <ContentItemSettingsMenu.ManageSurveysMenuItem onClick={() => setShowSurveyModal(true)} />

          {canAssign && (
            <ContentItemSettingsMenu.ViewLogsMenuItem onClick={() => setShowLogModal(true)} />
          )}

          <Divider />

          <ContentItemSettingsMenu.ToggleArchiveMenuItem
            isArchived={isArchived}
            onToggleArchive={handleToggleArchive}
            disabled={isTogglingArchive}
          />

          {isArchived && (
            <ContentItemSettingsMenu.DeleteMenuItem onClick={() => setShowDeleteModal(true)} />
          )}
        </>
      </ContentItemSettingsMenu.Menu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}

      {showCourseConfigModal && (
        <CourseConfigModal course={content} handleClose={() => setShowCourseConfigModal(false)} />
      )}

      {showSurveyModal && (
        <ContentSurveyModal content={content} handleClose={() => setShowSurveyModal(false)} />
      )}

      {showCourseFormModal && (
        <CourseFormModal
          handleClose={() => setShowCourseFormModal(false)}
          action="Update"
          courseIdUpdate={id}
          handleUploadCompleted={(publicIdAndSlug) => {
            toast.success(
              'New version uploaded successfully',
              'You can preview the new course version.'
            );
            if (!is_inline) {
              history.push(
                mapRoute('standAloneCourseDetail', {
                  public_id_and_slug: publicIdAndSlug,
                })
              );
            }
            setShowCourseFormModal(false);
          }}
        />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </ContentItemSettingsMenu.Provider>
  );
}

export default CourseContextMenu;

import { useQueryClient } from '@tanstack/react-query';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { ContentItemContextMenu } from '~/app/content-items/components/interfaces';
import { queries } from '~/queries';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import ContentSurveyModal from '~/app/shared/components/ContentSurveyModal';
import { useToggles } from '~/app/shared/hooks';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import ScheduleTrackModal from '~/app/tracks/components/ScheduleTrackModal';
import TrackItemCompletionStatsModal from '~/app/tracks/components/TrackItemCompletionStatsModal';
import { useCanDisplayTrackInsights } from '~/features/tracks/hooks/useCanDisplayTrackInsights';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import TrackArchiveUnarchiveConfirmationModal from './TrackArchiveUnarchiveConfirmationModal';
import { TrackInsightsModal } from '~/features/tracks/components/TrackInsightsModal';
import { useRouteActiveModalCtx } from '~/common/contexts/RouteActiveModal';
import {
  ContentItemSettingsMenu,
  useContentItemSettingsMenuState,
} from '~/common/components/ContentItemSettingsMenu';
import { Divider, ListItemIcon, MenuItem, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import CustomIcon from '~/app/shared/components/Icon';

const AvailableModals = {
  ArchiveUnarchive: 'archive_unarchive',
  Delete: 'delete',
  Survey: 'survey',
  Schedule: 'schedule',
  Log: 'log',
  TrackInsights: 'track_insights',
  CompletionStats: 'completion_stats',
} as const;

function TrackContextMenu(props: ContentItemContextMenu) {
  const { content, contextMenuExtraProps } = props;

  const { activeModal, setActiveModal, closeActiveModal } = useRouteActiveModalCtx();

  const { anchorEl, handleOpenMenu, handleCloseMenu } = useContentItemSettingsMenuState();

  const { public_id: publicId, content_type: contentType } = content;
  const isArchived = content.is_archived || Boolean(content.archival);
  const isTrack = contentType === CONTENT_TYPES.track;
  const isScheduleTrack = contentType === CONTENT_TYPES.scheduled_track;
  const isAssessment = contentType === CONTENT_TYPES.assessment;
  const { canSeeSettings, canManageAssignment, contentTypeRoute, currentRoute } =
    contextMenuExtraProps ?? {};

  const queryClient = useQueryClient();

  const { toggle_scheduled_tracks: toggleScheduledTracks } = useToggles();
  const canDisplayTrackInsights = useCanDisplayTrackInsights(content);

  const handleCloseArchiveUnarchiveModal = (shouldRefreshContent: boolean) => {
    if (shouldRefreshContent) {
      queryClient.invalidateQueries({
        queryKey: queries.content_items.details(publicId, 'detail').queryKey,
      });
    }

    closeActiveModal();
  };

  const handleDisplayTrackInsightsModal = () => {
    if (canDisplayTrackInsights) {
      setActiveModal(AvailableModals.TrackInsights);
      return;
    }

    setActiveModal(AvailableModals.CompletionStats);
  };

  const duplicateRouteUrl = `${mapRoute('trackCreate')}?cloned=${publicId}&origin=${currentRoute}`;
  const rosterRouteUrl = mapRoute('contentItemManageAssignments', {
    public_id_and_slug: content.public_id_and_slug,
  });

  return (
    <ContentItemSettingsMenu.Provider
      anchorEl={anchorEl}
      handleOpenMenu={handleOpenMenu}
      handleCloseMenu={handleCloseMenu}
    >
      {canSeeSettings && (
        <>
          <ContentItemSettingsMenu.ButtonTrigger />
          <ContentItemSettingsMenu.Menu>
            {contentTypeRoute && (
              <ContentItemSettingsMenu.EditMenuItem
                routeUrl={contentTypeRoute.edit({ public_id: publicId })}
              />
            )}

            {isTrack && toggleScheduledTracks && (
              <MenuItem
                disableRipple
                onClick={() => {
                  setActiveModal(AvailableModals.Schedule);
                  handleCloseMenu();
                }}
                data-testid="ScheduleMenuItem"
              >
                <ListItemIcon>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                <Typography>Schedule</Typography>
              </MenuItem>
            )}

            {isTrack && (
              <MenuItem disableRipple component={Link} to={duplicateRouteUrl}>
                <ListItemIcon>
                  <CustomIcon name="clone" width={16} height={16} color={colors.neutral600} />
                </ListItemIcon>
                <Typography>Duplicate</Typography>
              </MenuItem>
            )}

            <Divider />

            {canManageAssignment && (
              <>
                <ContentItemSettingsMenu.ViewRosterMenuItem routeUrl={rosterRouteUrl} />

                <MenuItem
                  disableRipple
                  onClick={() => {
                    handleDisplayTrackInsightsModal();
                    handleCloseMenu();
                  }}
                >
                  <ListItemIcon>
                    <MoreHorizIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>View Insights</Typography>
                </MenuItem>

                <ContentItemSettingsMenu.ViewLogsMenuItem
                  onClick={() => {
                    setActiveModal(AvailableModals.Log);
                    handleCloseMenu();
                  }}
                />
              </>
            )}

            {!isAssessment && (
              <ContentItemSettingsMenu.ManageSurveysMenuItem
                onClick={() => setActiveModal(AvailableModals.Survey)}
              />
            )}

            <Divider />

            <ContentItemSettingsMenu.ToggleArchiveMenuItem
              isArchived={isArchived}
              onToggleArchive={() => setActiveModal(AvailableModals.ArchiveUnarchive)}
              archiveText={isScheduleTrack ? 'Cancel' : 'Archive'}
              unarchiveText="Restore"
            />

            {isArchived && (
              <ContentItemSettingsMenu.DeleteMenuItem
                onClick={() => setActiveModal(AvailableModals.Delete)}
              />
            )}
          </ContentItemSettingsMenu.Menu>
        </>
      )}

      {/* Modals */}
      {activeModal === AvailableModals.ArchiveUnarchive && (
        <TrackArchiveUnarchiveConfirmationModal
          track={content}
          handleClose={({ shouldRefreshContent }) => {
            handleCloseArchiveUnarchiveModal(shouldRefreshContent);
          }}
        />
      )}

      {activeModal === AvailableModals.Delete && (
        <DeleteStandAloneModal content={content} handleClose={closeActiveModal} />
      )}

      {activeModal === AvailableModals.Survey && (
        <ContentSurveyModal content={content} handleClose={closeActiveModal} />
      )}

      {activeModal === AvailableModals.Schedule && (
        <ScheduleTrackModal track={content} handleClose={closeActiveModal} />
      )}

      {activeModal === AvailableModals.Log && (
        <ContentItemLogtrailModal content={content} handleClose={closeActiveModal} />
      )}

      {activeModal === AvailableModals.CompletionStats && (
        <TrackItemCompletionStatsModal track={content} handleClose={closeActiveModal} />
      )}

      {activeModal === AvailableModals.TrackInsights && (
        <TrackInsightsModal track={content} onClose={closeActiveModal} />
      )}
    </ContentItemSettingsMenu.Provider>
  );
}

export default TrackContextMenu;

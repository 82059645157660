import { useState } from 'react';

import {
  CONTENT_TYPES,
  LEARNING_TYPE_ICONS_MAPPING,
  LEARNING_TYPES,
} from '~/app/catalog/constants';
import { useContentTypeRoutes } from '~/app/catalog/hooks';
import { ContentItemContextMenu } from '~/app/content-items/components/interfaces';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import { useLabels } from '~/app/shared/hooks';
import { CONTENT_ITEM_PERMISSIONS } from '~/app/shared/permissions';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import { ConvertToArticleModal } from '~/app/task/components/TaskConverterModal';
import { includes } from 'lodash-es';
import {
  ContentItemSettingsMenu,
  useContentItemSettingsMenuState,
} from '~/common/components/ContentItemSettingsMenu';
import { Divider, ListItemIcon, MenuItem, Typography } from '@mui/material';
import CustomIcon from '~/app/shared/components/Icon';

function TaskContextMenu(props: ContentItemContextMenu) {
  const { content, contextMenuExtraProps } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConvertToArticleModal, setShowConvertToArticleModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const { anchorEl, handleOpenMenu, handleCloseMenu } = useContentItemSettingsMenuState();

  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const { label_article: labelArticle } = useLabels();

  const { isTrackItem } = contextMenuExtraProps;
  const { public_id: publicId, public_id_and_slug: publicIdAndSlug, permissions } = content;

  const canManage = includes(permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canAssign = includes(permissions, CONTENT_ITEM_PERMISSIONS.assign);

  if (!canManage && !canAssign) {
    return null;
  }

  const editRouteUrl = contentTypeRoutes[CONTENT_TYPES.task].edit({
    public_id: publicId,
  });
  const rosterRouteUrl = mapRoute('contentItemManageAssignments', {
    public_id_and_slug: publicIdAndSlug,
  });

  return (
    <ContentItemSettingsMenu.Provider
      anchorEl={anchorEl}
      handleOpenMenu={handleOpenMenu}
      handleCloseMenu={handleCloseMenu}
    >
      <ContentItemSettingsMenu.ButtonTrigger />
      <ContentItemSettingsMenu.Menu>
        {canManage && <ContentItemSettingsMenu.EditMenuItem routeUrl={editRouteUrl} />}

        {isTrackItem && (
          <MenuItem
            disableRipple
            onClick={() => {
              setShowConvertToArticleModal(true);
              handleCloseMenu();
            }}
          >
            <ListItemIcon>
              <CustomIcon
                name={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.tasks]}
                width={16}
                height={16}
                color={colors.neutral600}
              />
            </ListItemIcon>
            <Typography>Convert to {labelArticle}</Typography>
          </MenuItem>
        )}

        <Divider />

        {canAssign && (
          <>
            <ContentItemSettingsMenu.ViewRosterMenuItem routeUrl={rosterRouteUrl} />
            <ContentItemSettingsMenu.ViewLogsMenuItem onClick={() => setShowLogModal(true)} />
          </>
        )}

        <Divider />

        {canManage && (
          <ContentItemSettingsMenu.DeleteMenuItem onClick={() => setShowDeleteModal(true)} />
        )}
      </ContentItemSettingsMenu.Menu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}
      {showConvertToArticleModal && (
        <ConvertToArticleModal
          content={content}
          handleClose={() => setShowConvertToArticleModal(false)}
        />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </ContentItemSettingsMenu.Provider>
  );
}

export default TaskContextMenu;

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { queries } from '~/queries';
import BaseContainer from '~/app/navigation/components/BaseContainer';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { useEntityMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import HelpLink from '~/app/shared/components/HelpLink';
import InfoBox from '~/app/shared/components/InfoBox';
import PageTitle from '~/app/shared/components/PageTitle';
import { useOriginRoute, useUnsavedChangesPrompt } from '~/app/shared/hooks';
import SurveyForm from '~/app/surveys/components/SurveyForm';
import { editSurveyJSONSchema } from '~/app/surveys/components/SurveyForm/surveyJSONSchema';
import { transformFromPreviewToJSONEditorFormat } from '~/app/surveys/services';
import { useQuery } from '@tanstack/react-query';
import { useSurveyEdit } from '~/features/surveys/api/mutations.ts';
import { get } from 'lodash';

const SurveyEdit = () => {
  const { id: surveyId } = useParams();
  const [value, setValue] = useState(null);
  let isSaved = false;

  const history = useHistory();
  const { trackEntityActivity } = useEntityMetrics();
  const redirectionRoute = useOriginRoute(mapRoute('dashboardSurveys'));

  const {
    data: survey,
    isLoading,
    isError,
  } = useQuery({
    ...queries.surveys.preview(surveyId),
  });
  if (isError) {
    toast.error(`Fetching survey ${surveyId} failed.`);
  }

  const editSurveyMutation = useSurveyEdit(surveyId);

  useUnsavedChangesPrompt(
    !isSaved,
    'Your survey has not been saved. Are you sure you wish to leave this form without saving your work?'
  );

  const handleSubmit = async (payload) => {
    const response = await editSurveyMutation.mutateAsync(payload, {
      onSuccess: () => {
        /*queryClient.invalidateQueries({
            queryKey: queries.surveys.list._def,
          });
          queryClient.invalidateQueries(queries.surveys.detail_by_public_id(id).queryKey);*/
        toast.success('Survey successfully edited!');
      },
      onError: () => {
        toast.error('Submission failed, fix errors and try again.');
      },
    });
    if (response) {
      isSaved = true;
      trackEntityActivity({
        id: survey,
        isEdit: true,
        entityType: 'survey',
      });
      history.push(redirectionRoute);
    }
    return response;
  };

  useEffect(() => {
    if (survey) {
      setValue(transformFromPreviewToJSONEditorFormat(survey, true));
    }
  }, [survey]);

  return (
    <BaseContainer>
      <PageTitle title="Edit Survey" />
      <SurveyForm
        value={value}
        title="Edit Survey"
        schema={editSurveyJSONSchema}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        redirectionRoute={redirectionRoute}
        renderHeader={() =>
          get(survey, 'responses_count', 0) > 0 && (
            <InfoBox
              hideIcon
              margin="16px 0"
              type="warning"
              content={
                <>
                  Please note that altering the meaning of the questions, or changing the single /
                  multiple-choice options may make it harder to reason about the responses that have
                  been submitted this far.
                  <HelpLink url="https://help.plusplus.app/en/articles/5538317-how-to-edit-surveys">
                    Learn more about editing surveys.
                  </HelpLink>
                </>
              }
            />
          )
        }
      />
    </BaseContainer>
  );
};

export default SurveyEdit;

import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';
import { detailQueryKeyFactory, listQueryKeyFactory } from '~/app/data-fetching/utils';
import { ApiURLs, fetchURL } from '~/services/requests-base';

export type SurveyRelationshipEmailsStatus = 'answered' | 'unanswered';

async function fetchSurveyRelationshipEmails(id: number, status: SurveyRelationshipEmailsStatus) {
  const base = ApiURLs['surveys_api:survey_relationship_emails'](id);
  const url = `${base}?status=${status}`;

  const { data } = await fetchURL(url);
  return data;
}

async function fetchSurveyPreview(id: number) {
  const url = ApiURLs['surveys_api:preview'](id);
  const { data: response } = await fetchURL(url);
  return response;
}

export const surveys = createQueryKeys('surveys', {
  surveyRelationshipEmails: (id: number, status: SurveyRelationshipEmailsStatus) => ({
    queryKey: [id, status],
    queryFn: () => fetchSurveyRelationshipEmails(id, status),
  }),
  list: listQueryKeyFactory({ endpointName: 'surveys_api:surveys' }),
  detail_by_public_id: detailQueryKeyFactory({
    endpointName: 'surveys_api:details',
  }),
  preview: (id: number) => ({
    queryKey: [id],
    queryFn: () => fetchSurveyPreview(id),
  }),
});

export type SurveysQueryKeys = inferQueryKeys<typeof surveys>;

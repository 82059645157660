import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import BaseContainer from '~/app/navigation/components/BaseContainer';
import colors from '~/services/colors';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { mapRoute } from '~/services/requests';
import Breadcrumbs from '~/app/shared/components/Breadcrumbs';
import InfoBox from '~/app/shared/components/InfoBox';
import Loading from '~/app/shared/components/Loading';
import Text from '~/app/shared/components/Text';
import { useQuery } from '@tanstack/react-query';
import SurveyPreviewContent from './SurveyPreviewContent';
import { queries } from '~/queries';

const Content = styled.div`
  margin-top: 32px;
`;

const BreadcrumbWrapper = styled.div`
  max-width: 580px;
  margin: 0 auto;
`;

const InfoWrapper = styled.div`
  max-width: 580px;
  margin: 0 auto;
`;

const SurveyCard = styled.div`
  background: ${colors.neutral0};
  max-width: 580px;
  margin: 16px auto 48px;
  border-radius: 10px;
`;

const SurveyCardHeader = styled.div`
  padding: 24px 32px;
`;

const SurveyCardBody = styled.div`
  padding: 32px;
  border-top: 1px solid ${colors.neutral200};
`;

const SurveyCardFooter = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${colors.neutral200};

  display: flex;
  justify-content: center;
`;

const Subtitle = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
`;

const SurveyPreview = () => {
  const { trackActivity } = useMetrics();
  const { id } = useParams();
  const { data: survey, isLoading, isError, error } = useQuery(queries.surveys.preview(id));

  useEffect(() => {
    if (!survey) return;
    trackActivity(METRICS_ACTIVITIES.SURVEY_PREVIEW, {
      surveyId: survey.id,
    });
  }, [survey]);

  if (isError) {
    let errorMessage = error;
    if (error.detail) {
      errorMessage = error.detail;
    }
    return (
      <BaseContainer backgroundPanel>
        <Content>
          <SurveyCard>
            <SurveyCardHeader>
              <Text size="h2">{errorMessage}</Text>
            </SurveyCardHeader>
          </SurveyCard>
        </Content>
      </BaseContainer>
    );
  }

  if (isLoading) {
    return <Loading hasMargin />;
  }

  return (
    <BaseContainer backgroundPanel>
      <Content>
        <BreadcrumbWrapper>
          <Breadcrumbs
            items={[
              { link: mapRoute('dashboardSurveys'), label: 'Surveys Dashboard' },
              {
                link: mapRoute('dashboardSurveyInstances', { id: `${survey.id}` }),
                label: `Survey: ${survey.title}`,
              },
              { label: 'Survey Form Preview' },
            ]}
            variant="alternate"
          />
        </BreadcrumbWrapper>
        <InfoWrapper>
          <InfoBox
            type="warning"
            fontSize="14px"
            content="This page is just a preview. To collect responses for this survey, attach it to your events or content."
          />
        </InfoWrapper>
        <SurveyCard>
          <SurveyCardHeader>
            <Text size="h1" block>
              {survey.title}
            </Text>
            <Subtitle>
              <Text color={colors.neutral600} size="h3" medium>
                Survey: {survey.title}
              </Text>
            </Subtitle>
          </SurveyCardHeader>
          <SurveyCardBody>
            <SurveyPreviewContent survey={survey} />
          </SurveyCardBody>
          <SurveyCardFooter />
        </SurveyCard>
      </Content>
    </BaseContainer>
  );
};

export default SurveyPreview;
